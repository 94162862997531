:root {
  --bs-blue: #1e337c;
  --bs-indigo: #4b0082;
  --bs-purple: #a020f0;
  --bs-pink: #d63384;
  --bs-red: #aa0000;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffa600;
  --bs-green: #2cbe00;
  --bs-teal: #00a372;
  --bs-cyan: #0073ff;
  --bs-black: #000;
  --bs-white: #fff;

  --bs-gray-100: #f8f9fa;
  --bs-gray-150: #f6f6f6;
  --bs-gray-200: #e9ecef;
  --bs-gray-250: #dde1e4;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-750: #444a50;
  --bs-gray-800: #343a40;
  --bs-gray-850: #262b2f;
  --bs-gray-900: #212529;
  --bs-gray-950: #1a1d20;

}

.cLink {
  color: var(--text-link) !important;
}

.cBlue{
  color: var(--bs-blue) !important;
}

.cIndigo{
  color: var(--bs-indigo) !important;
}

.cPurple{
  color: var(--bs-purple) !important;
}

.cPink{
  color: var(--bs-pink) !important;
}

.cRed{
  color: var(--bs-red) !important;
}

.cOrange{
  color: var(--bs-orange) !important;
}

.cYellow{
  color: var(--bs-yellow) !important;
}

.cGreen{
  color: var(--bs-green) !important;
}

.cTeal{
  color: var(--bs-teal) !important;
}

.cCyan{
  color: var(--bs-cyan) !important;
}

.cBlack{
  color: var(--bs-black) !important;
}

.cWhite{
  color: var(--bs-white) !important;
}

.cGraffite{
  color: var(--bs-gray-800) !important;
}

.cLink{
  color: var(--bs-cyan) !important;
}

.cGray100{
  color: var(--bs-gray-100) !important;
}

.cGray200{
  color: var(--bs-gray-200) !important;
}

.cGray300{
  color: var(--bs-gray-300) !important;
}

.cGray400{
  color: var(--bs-gray-400) !important;
}

.cGray500{
  color: var(--bs-gray-500) !important;
}

.cGray600{
  color: var(--bs-gray-600) !important;
}

.cGray700{
  color: var(--bs-gray-700) !important;
}

.cGray800{
  color: var(--bs-gray-800) !important;
}

.cGray900{
  color: var(--bs-gray-900) !important;
}

.cDarkRed{
  color: var(--bs-red-dark) !important;
}

.bgBlue{
  background-color: var(--bs-blue) !important;
}

.bgIndigo{
  background-color: var(--bs-indigo) !important;
}

.bgPurple{
  background-color: var(--bs-purple) !important;
}

.bgPink{
  background-color: var(--bs-pink) !important;
}

.bgRed{
  background-color: var(--bs-red) !important;
}

.bgOrange{
  background-color: var(--bs-orange) !important;
}

.bgYellow{
  background-color: var(--bs-yellow) !important;
}

.bgGreen{
  background-color: var(--bs-green) !important;
}

.bgTeal{
  background-color: var(--bs-teal) !important;
}

.bgCyan{
  background-color: var(--bs-cyan) !important;
}

.bgBlack{
  background-color: var(--bs-black) !important;
}

.bgWhite{
  background-color: var(--bs-white) !important;
}

.bgGraffite{
  background-color: var(--bs-gray-800) !important;
}

.bgGray100{
  background-color: var(--bs-gray-100) !important;
}

.bgGray150{
  background-color: var(--bs-gray-150) !important;
}

.bgGray200{
  background-color: var(--bs-gray-200) !important;
}

.bgGray300{
  background-color: var(--bs-gray-300) !important;
}

.bgGray400{
  background-color: var(--bs-gray-400) !important;
}

.bgGray500{
  background-color: var(--bs-gray-500) !important;
}

.bgGray600{
  background-color: var(--bs-gray-600) !important;
}

.bgGray700{
  background-color: var(--bs-gray-700) !important;
}

.bgGray800{
  background-color: var(--bs-gray-800) !important;
}

.bgGray900{
  background-color: var(--bs-gray-900) !important;
}

.bgLightBlueTransp {
  background-color:var(--light-blue-transp) !important;
}

.bg {
  background-color: var(--bg) !important;
}

.bgContrast {
  background-color: var(--bg-contrast) !important;
}

.bgContrastSoft {
  background-color: var(--bg-contrast-soft) !important;
}

.bgContrastSoftDarker {
  background-color: var(--bg-contrast-soft-darker) !important;
}

.bgMenu {
  background-color: var(--bg-menu) !important;
}

.bgMenuHover {
  background-color: var(--bg-menu-hover) !important;
}

.bgDropdown {
  background-color: var(--bg-dropdown) !important;
}

.bgDropdownHover {
  background-color: var(--bg-dropdown-hover) !important;
}

.bgNavbar {
  background-color: var(--bg-navbar) !important;
}

.bgFooter {
  background-color: var(--bg-footer) !important;
}

.bgOverlayDarkSoft {
  background-color: var(--bg-overlay-dark-soft) !important;
}

.bgOverlayDark {
  background-color: var(--bg-overlay-dark) !important;
}

.bgOverlayDarker {
  background-color: var(--bg-overlay-darker) !important;
}

.bgOverlayDarkest {
  background-color: var(--bg-overlay-darkest) !important;
}

.bgOverlayLightSoft {
  background-color: var(--bg-overlay-light-soft) !important;
}

.bgOverlayLight {
  background-color: var(--bg-overlay-light) !important;
}

.bgOverlayLighter {
  background-color: var(--bg-overlay-lighter) !important;
}

.bViolet {
  background-color: var(--bs-violet) !important;
}

.bLightBlue {
  background-color: var(--bs-light-blue) !important;
}

.bLightCyan {
  background-color: var(--bs-light-cyan) !important;
}

.bDarkGreen {
  background-color: var(--bs-dark-green) !important;
}

.bDarkPink {
  background-color: var(--bs-dark-pink) !important;
}

.bDarkRose {
  background-color: var(--bs-dark-rose) !important;
}

.textContrast {
  color: var(--text-contrast) !important;
}

.textContrastColor {
  color: var(--text-contrast-color) !important;
}

.textContrastColorDiff {
  color: var(--text-contrast-color-diff) !important;
}

.textContrastColorBlue {
  color: var(--text-contrast-color-blue) !important;
}

.textContrastColorYellow {
  color: var(--text-contrast-color-yellow) !important;
}

.textContrastColorGreen {
  color: var(--text-contrast-color-green) !important;
}

.textContrastColorPink {
  color: var(--text-contrast-color-pink) !important;
}

.textContrastColorRose {
  color: var(--text-contrast-color-rose) !important;
}

.textContrastColorRed {
  color: var(--text-contrast-color-red) !important;
}

.textContrastColorRoseLight {
  color: var(--bs-dark-rose-light) !important;
}

.textContrastLow {
  color: var(--text-contrast-low) !important;
}

.hovBlue:hover{
  color: var(--bs-blue) !important;
}

.hovIndigo:hover{
  color: var(--bs-indigo) !important;
}

.hovPurple:hover{
  color: var(--bs-purple) !important;
}

.hovPink:hover{
  color: var(--bs-pink) !important;
}

.hovRed:hover{
  color: var(--bs-red) !important;
}

.hovOrange:hover{
  color: var(--bs-orange) !important;
}

.hovYellow:hover{
  color: var(--bs-yellow) !important;
}

.hovGreen:hover{
  color: var(--bs-green) !important;
}

.hovTeal:hover{
  color: var(--bs-teal) !important;
}

.hovCyan:hover{
  color: var(--bs-cyan) !important;
}

.hovBlack:hover{
  color: var(--bs-black) !important;
}

.hovWhite:hover{
  color: var(--bs-white) !important;
}

.hovGraffite:hover{
  color: var(--bs-gray-800) !important;
}

.hovGray100:hover{
  color: var(--bs-gray-100) !important;
}

.hovGray200:hover{
  color: var(--bs-gray-200) !important;
}

.hovGray300:hover{
  color: var(--bs-gray-300) !important;
}

.hovGray400:hover{
  color: var(--bs-gray-400) !important;
}

.hovGray500:hover{
  color: var(--bs-gray-500) !important;
}

.hovGray600:hover{
  color: var(--bs-gray-600) !important;
}

.hovGray700:hover{
  color: var(--bs-gray-700) !important;
}

.hovGray800:hover{
  color: var(--bs-gray-800) !important;
}

.hovGray900:hover{
  color: var(--bs-gray-900) !important;
}

.hovbgBlue:hover{
  background-color: var(--bs-blue) !important;
}

.hovbgIndigo:hover{
  background-color: var(--bs-indigo) !important;
}

.hovbgPurple:hover{
  background-color: var(--bs-purple) !important;
}

.hovbgPink:hover{
  background-color: var(--bs-pink) !important;
}

.hovbgRed:hover{
  background-color: var(--bs-red) !important;
}

.hovbgOrange:hover{
  background-color: var(--bs-orange) !important;
}

.hovbgYellow:hover{
  background-color: var(--bs-yellow) !important;
}

.hovbgGreen:hover{
  background-color: var(--bs-green) !important;
}

.hovbgTeal:hover{
  background-color: var(--bs-teal) !important;
}

.hovbgCyan:hover{
  background-color: var(--bs-cyan) !important;
}

.hovbgBlack:hover{
  background-color: var(--bs-black) !important;
}

.hovbgWhite:hover{
  background-color: var(--bs-white) !important;
}

.hovbgGraffite:hover{
  background-color: var(--bs-gray-800) !important;
}

.hovbgGray100:hover{
  background-color: var(--bs-gray-100) !important;
}

.hovbgGray200:hover{
  background-color: var(--bs-gray-200) !important;
}

.hovbgGray300:hover{
  background-color: var(--bs-gray-300) !important;
}

.hovbgGray400:hover{
  background-color: var(--bs-gray-400) !important;
}

.hovbgGray500:hover{
  background-color: var(--bs-gray-500) !important;
}

.hovbgGray600:hover{
  background-color: var(--bs-gray-600) !important;
}

.hovbgGray700:hover{
  background-color: var(--bs-gray-700) !important;
}

.hovbgGray800:hover{
  background-color: var(--bs-gray-800) !important;
}

.hovbgGray900:hover{
  background-color: var(--bs-gray-900) !important;
}

.hovbgLightBlueTransp:hover {
  background-color:var(--light-blue-transp) !important;
}

.hovbgOverlayDark:hover{
  background-color: var(--bg-overlay-dark) !important;
}

.hovbgOverlayLight:hover{
  background-color: var(--bg-overlay-light) !important;
}

.hovBgContrastSoft:hover {
  background-color: var(--bg-contrast-soft) !important;
}

.hovBgContrastDark:hover {
  background-color: var(--bg-contrast-soft-darker) !important;
}

.hovBgContrastLighter:hover {
  background-color: var(--bg-contrast-soft-lighter) !important;
}

.borBlue {
  border-color: var(--bs-blue) !important;
}

.borIndigo {
  border-color: var(--bs-indigo) !important;
}

.borPurple {
  border-color: var(--bs-purple) !important;
}

.borPink {
  border-color: var(--bs-pink) !important;
}

.borRed {
  border-color: var(--bs-red) !important;
}

.borOrange {
  border-color: var(--bs-orange) !important;
}

.borYellow {
  border-color: var(--bs-yellow) !important;
}

.borGreen {
  border-color: var(--bs-green) !important;
}

.borTeal {
  border-color: var(--bs-teal) !important;
}

.borCyan {
  border-color: var(--bs-cyan) !important;
}

.borBlack {
  border-color: var(--bs-black) !important;
}

.borWhite {
  border-color: var(--bs-white) !important;
}

.borGraffite {
  border-color: var(--bs-gray-800) !important;
}

.borGray100 {
  border-color: var(--bs-gray-100) !important;
}

.borGray200 {
  border-color: var(--bs-gray-200) !important;
}

.borGray300 {
  border-color: var(--bs-gray-300) !important;
}

.borGray400 {
  border-color: var(--bs-gray-400) !important;
}

.borGray500 {
  border-color: var(--bs-gray-500) !important;
}

.borGray600 {
  border-color: var(--bs-gray-600) !important;
}

.borGray700 {
  border-color: var(--bs-gray-700) !important;
}

.borGray800 {
  border-color: var(--bs-gray-800) !important;
}

.borGray900 {
  border-color: var(--bs-gray-900) !important;
}
