.wp10 {
  width: 10px !important;
}

.wp20 {
  width: 20px !important;
}

.wp25  {
  width: 25px !important;
}

.wp30 {
  width: 30px !important;
}

.wp35 {
  width: 35px !important;
}

.wp40 {
  width: 40px !important;
}

.wp45 {
  width: 45px !important;
}

.wp50 {
  width: 50px !important;
}

.wp55 {
  width: 55px !important;
}

.wp60 {
  width: 60px !important;
}

.wp65 {
  width: 65px !important;
}

.wp70 {
  width: 70px !important;
}

.wp75 {
  width: 75px !important;
}

.wp80 {
  width: 80px !important;
}

.wp85 {
  width: 85px !important;
}

.wp90 {
  width: 90px !important;
}

.wp95 {
  width: 95px !important;
}

.wp100 {
  width: 100px !important;
}

.wp125 {
  width: 125px !important;
}

.wp150 {
  width: 150px !important;
}

.wp175 {
  width: 175px !important;
}

.wp200 {
  width: 200px !important;
}

.wp225 {
  width: 225px !important;
}

.wp250 {
  width: 250px !important;
}

.wp275 {
  width: 275px !important;
}

.wp300 {
  width: 300px !important;
}

.wp350 {
  width: 350px !important;
}

.wp400 {
  width: 400px !important;
}

.wp450 {
  width: 450px !important;
}

.wp500 {
  width: 500px !important;
}

.wp550 {
  width: 550px !important;
}

.wp600 {
  width: 600px !important;
}

.wp650 {
  width: 650px !important;
}

.wp700 {
  width: 700px !important;
}

.wp750 {
  width: 750px !important;
}

.wp800 {
  width: 800px !important;
}

.wp850 {
  width: 850px !important;
}

.wp900 {
  width: 900px !important;
}

.wp950 {
  width: 950px !important;
}

.wp1000 {
  width: 1000px !important;
}

.wp1050 {
  width: 1050px !important;
}

.wp1100 {
  width: 1100px !important;
}

.wp1150 {
  width: 1150px !important;
}

.wp1200 {
  width: 1200px !important;
}

.wp1250 {
  width: 1250px !important;
}

.wp1300 {
  width: 1300px !important;
}

.wp1350 {
  width: 1350px !important;
}

.wp1400 {
  width: 1400px !important;
}

.w10 {
  width: 10% !important;
}

.w15 {
  width: 15% !important;
}

.w20 {
  width: 20% !important;
}

.w25 {
  width: 25% !important;
}

.w30 {
  width: 30% !important;
}

.w35 {
  width: 35% !important;
}

.w40 {
  width: 40% !important;
}

.w45 {
  width: 45% !important;
}

.w50 {
  width: 50% !important;
}

.w55 {
  width: 55% !important;
}

.w60 {
  width: 60% !important;
}

.w65 {
  width: 65% !important;
}

.w70 {
  width: 70% !important;
}

.w75 {
  width: 75% !important;
}

.w80 {
  width: 80% !important;
}

.w85 {
  width: 85% !important;
}

.w90 {
  width: 90% !important;
}

.w95 {
  width: 95% !important;
}

.w100 {
  width: 100% !important;
}

.wpMax100 {
  max-width: 100px !important;
}

.wpMax125 {
  max-width: 125px !important;
}

.wpMax150 {
  max-width: 150px !important;
}

.wpMax175 {
  max-width: 175px !important;
}

.wpMax200 {
  max-width: 200px !important;
}

.wpMax225 {
  max-width: 225px !important;
}

.wpMax250 {
  max-width: 250px !important;
}

.wpMax275 {
  max-width: 275px !important;
}

.wpMax300 {
  max-width: 300px !important;
}

.wpMax325 {
  max-width: 325px !important;
}

.wpMax350 {
  max-width: 350px !important;
}

.wpMax375 {
  max-width: 375px !important;
}

.wpMax400 {
  max-width: 400px !important;
}

.wpMax425 {
  max-width: 425px !important;
}

.wpMax450 {
  max-width: 450px !important;
}

.wpMax475 {
  max-width: 475px !important;
}

.wpMax500 {
  max-width: 500px !important;
}

.wpMax550 {
  max-width: 550px !important;
}

.wpMax600 {
  max-width: 600px !important;
}

.wpMax650 {
  max-width: 650px !important;
}

.wpMax700 {
  max-width: 700px !important;
}

.wpMax750 {
  max-width: 750px !important;
}

.wpMax800 {
  max-width: 800px !important;
}

.wpMax850 {
  max-width: 850px !important;
}

.wpMax900 {
  max-width: 900px !important;
}

.wpMax950 {
  max-width: 950px !important;
}

.wpMax1000 {
  max-width: 1000px !important;
}

.wpMax1050 {
  max-width: 1050px !important;
}

.wpMax1100 {
  max-width: 1100px !important;
}

.wpMax1150 {
  max-width: 1150px !important;
}

.wpMax1200 {
  max-width: 1200px !important;
}

.wpMax1250 {
  max-width: 1250px !important;
}

.wpMax1300 {
  max-width: 1300px !important;
}

.wpMax1350 {
  max-width: 1350px !important;
}

.wpMax1400 {
  max-width: 1400px !important;
}

.wMax10 {
  max-width: 10% !important;
}

.wMax15 {
  max-width: 15% !important;
}

.wMax20 {
  max-width: 20% !important;
}

.wMax25 {
  max-width: 25% !important;
}

.wMax30 {
  max-width: 30% !important;
}

.wMax35 {
  max-width: 35% !important;
}

.wMax40 {
  max-width: 40% !important;
}

.wMax45 {
  max-width: 45% !important;
}

.wMax50 {
  max-width: 50% !important;
}

.wMax55 {
  max-width: 55% !important;
}

.wMax60 {
  max-width: 60% !important;
}

.wMax65 {
  max-width: 65% !important;
}

.wMax70 {
  max-width: 70% !important;
}

.wMax75 {
  max-width: 75% !important;
}

.wMax80 {
  max-width: 80% !important;
}

.wMax85 {
  max-width: 85% !important;
}

.wMax90 {
  max-width: 90% !important;
}

.wMax95 {
  max-width: 95% !important;
}

.wMax100 {
  max-width: 100% !important;
}

/* min sizes */

.wpMin100 {
  min-width: 100px !important;
}

.wpMin125 {
  min-width: 125px !important;
}

.wpMin150 {
  min-width: 150px !important;
}

.wpMin175 {
  min-width: 175px !important;
}

.wpMin200 {
  min-width: 200px !important;
}

.wpMin225 {
  min-width: 225px !important;
}

.wpMin250 {
  min-width: 250px !important;
}

.wpMin275 {
  min-width: 275px !important;
}

.wpMin300 {
  min-width: 300px !important;
}

.wpMin325 {
  min-width: 325px !important;
}

.wpMin350 {
  min-width: 350px !important;
}

.wpMin375 {
  min-width: 375px !important;
}

.wpMin400 {
  min-width: 400px !important;
}

.wpMin425 {
  min-width: 425px !important;
}

.wpMin450 {
  min-width: 450px !important;
}

.wpMin475 {
  min-width: 475px !important;
}

.wpMin500 {
  min-width: 500px !important;
}

.wpMin550 {
  min-width: 550px !important;
}

.wpMin600 {
  min-width: 600px !important;
}

.wpMin650 {
  min-width: 650px !important;
}

.wpMin700 {
  min-width: 700px !important;
}

.wpMin750 {
  min-width: 750px !important;
}

.wpMin800 {
  min-width: 800px !important;
}

.wpMin850 {
  min-width: 850px !important;
}

.wpMin900 {
  min-width: 900px !important;
}

.wpMin950 {
  min-width: 950px !important;
}

.wpMin1000 {
  min-width: 1000px !important;
}

.wpMin1050 {
  min-width: 1050px !important;
}

.wpMin1100 {
  min-width: 1100px !important;
}

.wpMin1150 {
  min-width: 1150px !important;
}

.wpMin1200 {
  min-width: 1200px !important;
}

.wpMin1250 {
  min-width: 1250px !important;
}

.wpMin1300 {
  min-width: 1300px !important;
}

.wpMin1350 {
  min-width: 1350px !important;
}

.wpMin1400 {
  min-width: 1400px !important;
}

.wMin10 {
  min-width: 10% !important;
}

.wMin15 {
  min-width: 15% !important;
}

.wMin20 {
  min-width: 20% !important;
}

.wMin25 {
  min-width: 25% !important;
}

.wMin30 {
  min-width: 30% !important;
}

.wMin35 {
  min-width: 35% !important;
}

.wMin40 {
  min-width: 40% !important;
}

.wMin45 {
  min-width: 45% !important;
}

.wMin50 {
  min-width: 50% !important;
}

.wMin55 {
  min-width: 55% !important;
}

.wMin60 {
  min-width: 60% !important;
}

.wMin65 {
  min-width: 65% !important;
}

.wMin70 {
  min-width: 70% !important;
}

.wMin75 {
  min-width: 75% !important;
}

.wMin80 {
  min-width: 80% !important;
}

.wMin85 {
  min-width: 85% !important;
}

.wMin90 {
  min-width: 90% !important;
}

.wMin95 {
  min-width: 95% !important;
}

.wMin100 {
  min-width: 100% !important;
}

/*  kept for compatibility */
/* 
.wp20 {
  width: 20px !important;
}

.wp30 {
  width: 30px !important;
}

.wp35 {
  width: 35px !important;
}

.wp40 {
  width: 40px !important;
}

.wp50 {
  width: 50px !important;
}

.wp60 {
  width: 60px !important;
}

.wp80 {
  width: 80px !important;
}

.w25 {
  width: 25% !important;
}

.min200P {
  min-width: 200px;
}

.wp100 {
  width: 100px;
}

.wp120 {
  width: 120px;
}

.wp150 {
  width: 150px !important;
}

.wp180 {
  width: 180px;
}

.wp200 {
  width: 200px !important;
}

.wp220 {
  width: 200px;
}

.wp250 {
  width: 250px !important;
}

.wp300 {
  width: 300px !important;
}

.wp400 {
  width: 400px;
}

.wp500 {
  width: 500px;
}

.wp25 {
  width: 25px !important;
  display: inline-block;
}

.w100 {
  width: 100% !important;
}

.h100 {
  height: 100vh;
}

.w10 {
  width: 10% !important;
}

.w20 {
  width: 20% !important;
}

.w30 {
  width: 30% !important;
}

.w40 {
  width: 40% !important;
}

.w50 {
  width: 50% !important;
}

.w60 {
  width: 60% !important;
}

.w70 {
  width: 70% !important;
}

.w80 {
  width: 80% !important;
}

.w90 {
  width: 90% !important;
}

.wpMin130 {
  min-width: 130px !important;
}

.mw200 {
  max-width: 200px;
}

.mw250 {
  max-width: 250px;
}

.mw300 {
  max-width: 300px;
}

.mw350 {
  max-width: 350px;
}

.mw400 {
  max-width: 400px;
}

.mw450 {
  max-width: 450px;
}

.mw500 {
  max-width: 500px;
}

.mw800 {
  max-width: 800px;
}

.mw400 {
  max-width: 400px;
}

.mw600 {
  max-width: 600px;
}

.mw800 {
  max-width: 800px;
} */
