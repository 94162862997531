

:root {
  --bs-blue-darker: #000e40;
  --max-container: 1200px;

  --bg-navbar: light-dark(var(--bs-white), var(--bs-gray-900)) !important;
  --text-navbar: light-dark(var(--bs-gray-800), var(--bs-gray-100)) !important;

  --bg-footer: light-dark(var(--bs-gray-100), var(--bs-gray-900)) !important;
  --text-footer: light-dark(var(--bs-gray-600), var(--bs-gray-400)) !important;

}
