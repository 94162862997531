
// scss-docs-start gray-color-variables
$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #111214 !default;
$black:    #000 !default;
// scss-docs-end gray-color-variables

// scss-docs-start color-variables
$blue:    #0c1e60;
$indigo:  #4b0082;
$purple:  #a020f0;
$pink:    #d63384;
$red:     #aa0011;
$orange:  #fd7e14;
$yellow:  #ffa600;
$green:   #2cbe00;
$teal:    #00a372;
$cyan:    #0073ff;
// scss-docs-end color-variables

// Importações do Bootstrap
@import '~bootstrap/scss/functions'; // Funções do Bootstrap
@import '~bootstrap/scss/variables'; // Variáveis do Bootstrap (já com as personalizações)
@import '~bootstrap/scss/variables-dark'; // Suporte para modo escuro
@import '~bootstrap/scss/maps'; // Mapas (para acessar e manipular as variáveis)
@import '~bootstrap/scss/mixins'; // Mixins do Bootstrap
@import '~bootstrap/scss/utilities'; // Utilitários personalizados

// Layout & components
@import '~bootstrap/scss/root'; // Variáveis e estilos globais
@import '~bootstrap/scss/reboot'; // Estilos de reinicialização do navegador
@import '~bootstrap/scss/type'; // Tipografia
@import '~bootstrap/scss/images'; // Estilos para imagens
@import '~bootstrap/scss/containers'; // Containers responsivos
@import '~bootstrap/scss/grid'; // Sistema de grid
@import '~bootstrap/scss/tables'; // Tabelas
@import '~bootstrap/scss/forms'; // Formulários
@import '~bootstrap/scss/buttons'; // Botões
@import '~bootstrap/scss/transitions'; // Transições CSS
@import '~bootstrap/scss/dropdown'; // Dropdown
@import '~bootstrap/scss/button-group'; // Grupos de botões
@import '~bootstrap/scss/nav'; // Navegação
@import '~bootstrap/scss/navbar'; // Barra de navegação
@import '~bootstrap/scss/card'; // Cartões
@import '~bootstrap/scss/accordion'; // Acordeão
@import '~bootstrap/scss/breadcrumb'; // Migalhas de navegação
@import '~bootstrap/scss/pagination'; // Paginação
@import '~bootstrap/scss/badge'; // Distintivos
@import '~bootstrap/scss/alert'; // Alertas
@import '~bootstrap/scss/progress'; // Barras de progresso
@import '~bootstrap/scss/list-group'; // Grupos de listas
@import '~bootstrap/scss/close'; // Fechar elementos
@import '~bootstrap/scss/toasts'; // Notificações
@import '~bootstrap/scss/modal'; // Modais
@import '~bootstrap/scss/tooltip'; // Tooltips
@import '~bootstrap/scss/popover'; // Popovers
@import '~bootstrap/scss/carousel'; // Carrosséis
@import '~bootstrap/scss/spinners'; // Spinners de carregamento
@import '~bootstrap/scss/offcanvas'; // Offcanvas (menus laterais)
@import '~bootstrap/scss/placeholders'; // Espaços reservados (placeholders)

// Helpers
@import '~bootstrap/scss/helpers'; // Auxiliares como margens e padding

// Utilities API
@import '~bootstrap/scss/utilities/api'; // Utilitários como display, visibilidade, etc.
