@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&family=Roboto+Flex:opsz,wght@8..144,100..1000&display=swap');

:root {
  --bs-violet: #8f00ff;
  --bs-light-blue: #3354cc;
  --bs-light-cyan: #00c3ff;
  --bs-dark-green: #196c00;
  --bs-dark-pink: #f60087;
  --bs-dark-rose: #6f003d;
  --bs-dark-rose-light: #723858;
  --bs-red-dark: #ff2424;
  --bs-yellow-dark: #bc7a00;

  --bluer-transp: #00237be2 !important;
  --light-blue-transp: #2e4cb672 !important;

  --green-dark: #1f8300;
  --green-light: #308930;
  --greener-light: #00c400ca;
  --red-light: #aa0011c2;
  --grey-light: #636363b9;

  --bg-overlay-dark-soft: rgba(0, 0, 0, 0.4) !important;
  --bg-overlay-dark: rgba(0, 0, 0, 0.6) !important;
  --bg-overlay-darker: rgba(0, 0, 0, 0.8) !important;
  --bg-overlay-darkest: rgba(0, 0, 0, 0.9) !important;

  --bg-overlay-red:  rgba(255, 0, 0, 0.129)!important;

  --bg-overlay-light-soft: rgba(255, 255, 255, 0.4) !important;
  --bg-overlay-light: rgba(255, 255, 255, 0.6) !important;
  --bg-overlay-lighter: rgba(255, 255, 255, 0.8) !important;

  --text-overlay-dark: rgba(255, 255, 255, 0.9) !important;
  --text-overlay-light: rgba(0, 0, 0, 0.9) !important;

  /* default */

  --font-title: 'Mulish', Arial, Helvetica, sans-serif;
  --font-soft: 'Mulish', Arial, Helvetica, sans-serif;
  --font-regular: 'Roboto Flex', Arial, Helvetica, sans-serif;

  color-scheme: light dark;

  /* common */
  --bg: light-dark(var(--bs-gray-100), var(--bs-gray-800)) !important;
  --bg-contrast: light-dark(var(--bs-gray-200), var(--bs-gray-700)) !important;
  --bg-contrast-soft: light-dark(
    var(--bs-gray-150),
    var(--bs-gray-750)
  ) !important;

  --bg-contrast-soft-darker: light-dark(
    var(--bs-gray-200),
    var(--bs-gray-850)
  ) !important;

  --bg-contrast-soft-lighter: light-dark(
    var(--bs-white),
    var(--bs-gray-750)
  ) !important;

  --bg-border-detail: light-dark(var(--light-blue-transp), var(--bs-yellow-dark)) !important;

  --text: light-dark(var(--bs-gray-800), var(--bs-gray-100)) !important;
  --text-contrast: light-dark(var(--bs-black), var(--bs-white)) !important;
  --text-contrast-low: light-dark(
    var(--bs-gray-600),
    var(--bs-gray-600)
  ) !important;
  --text-contrast-lower: light-dark(
    var(--bs-gray-200),
    var(--bs-gray-700)
  ) !important;
  --text-contrast-color: light-dark(
    var(--bs-light-blue),
    var(--bs-yellow)
  ) !important;

  --text-contrast-color-diff: light-dark(
    var(--bs-light-blue),
    var(--bs-light-blue)
  ) !important;

  --text-contrast-color-blue: var(--bs-light-blue) !important;
  --text-contrast-color-yellow: var(--bs-yellow) !important;
  --text-contrast-color-green: var(--bs-dark-green) !important;
  --text-contrast-color-pink: var(--bs-dark-pink) !important;
  --text-contrast-color-rose: light-dark(var(--bs-dark-rose), var(--bs-dark-rose-light)) !important;
  --text-contrast-color-red: light-dark(var(--bs-red), var(--bs-red-dark)) !important;

  --text-link: light-dark(var(--bs-cyan), var(--bs-light-cyan)) !important;

  /* menu */
  --bg-menu: light-dark(var(--bs-gray-200), var(--bs-gray-700)) !important;
  --text-menu: light-dark(var(--bs-gray-700), var(--bs-gray-200)) !important;

  --bg-menu-soft: light-dark(var(--bs-gray-150), var(--bs-gray-850)) !important;

  --menu-svg: light-dark(var(--bs-gray-700), var(--bs-gray-200)) !important;
  --menu-svg-active: light-dark(var(--bs-yellow), var(--bs-yellow)) !important;

  --bg-menu-hover: light-dark(
    var(--bs-gray-700),
    var(--bs-gray-900)
  ) !important;
  --text-menu-hover: light-dark(var(--bs-gray-200), var(--bs-white)) !important;
  --menu-svg-hover: light-dark(var(--bs-white), var(--bs-gray-100)) !important;

  /* tooltip */
  --bg-tooltip: light-dark(var(--bs-gray-400), var(--bs-gray-600)) !important;
  --text-tooltip: light-dark(var(--bs-black), var(--bs-white)) !important;

  /* dropdown */
  --bg-dropdown: var(--bg-menu) !important;
  --text-dropdown: var(--text-menu) !important;

  --bg-dropdown-hover: light-dark(
    var(--bg-gray-700),
    var(--bg-gray-300)
  ) !important;
  --text-dropdown-hover: light-dark(
    var(--bs-white),
    var(--bs-black)
  ) !important;

  /* navbar */
  --bg-navbar: light-dark(var(--bs-gray-400), var(--bs-gray-900)) !important;
  --text-navbar: light-dark(var(--bs-gray-600), var(--bs-gray-400)) !important;

  /* footer */
  --bg-footer: light-dark(var(--bs-gray-400), var(--bs-gray-900)) !important;
  --text-footer: light-dark(var(--bs-gray-600), var(--bs-gray-400)) !important;
  /*  */

  /* max-container main width */
  --max-container: 1200px;

  /* spinner */
}

html {
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: var(--font-regular);
  background-color: var(--bg);
  color: var(--text);
}

a {
  color: var(--text-link) !important;
  text-decoration: none !important;
}

a > .btn-link,
.spanLinkButton > svg {
  color: var(--text-link) !important;
  fill: var(--text-link) !important;
  text-decoration: none !important;
}

.setWidth {
  width: 100%;
  padding: 3px;
  max-width: var(--max-container);
  margin: auto;
}

.light {
  color-scheme: light;
}

.dark {
  color-scheme: dark;
}

.navtheme {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
  padding: 5px;
}

.textContrast{
  color: var(--text-contrast);
}

.textContrastColor{
  color: var(--text-contrast-color);
}

.textContrastLow{
  color: var(--text-contrast-low);
}

.mousePointerHover:hover {
  cursor: pointer;
}

.homeLogo{
  width: 50%;
  max-width: 200px;
}

.wrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.mAuto{
  margin: auto;
}

.formContainerRounded {
  border-radius: 15px;
  padding: 20px;
  background-color: var(--bg-contrast);  
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

.bgTransparent {
  background-color: transparent !important;
}

.tooltip {
  position: relative;
  font-size: 0.7em !important;
  background-color: var(--bg-menu-soft) !important;
  border: unset;
  display: inline;
  width:  fit-content !important; 
  text-align: center;
  height: auto !important;
  z-index: 0;
  inset: auto auto 0.2em !important;
}

.tooltip .tooltip-inner {
  background-color: var(--bg-tooltip) !important;
  color: var(--text-tooltip);
  border-radius: 0.3em;
}

.tooltip-arrow::before {
  border-top-color: var(--bg-tooltip) !important;
}

@media screen and (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}
