.cGreen > svg {
  fill: var(--bs-green);
  height: 20px;
  width: 20px;
}

.cBlue > svg {
  fill: var(--bs-cyan);
  height: 20px;
  width: 20px;
}

.cRed > svg {
  fill: var(--bs-red);
  height: 20px;
  width: 20px;
}

.cGrey > svg {
  fill: var(--bs-gray);
  height: 20px;
  width: 20px;
}

.cRed > svg:hover,
.cGreen > svg:hover,
.cGrey > svg:hover {
  fill: var(--bs-cyan);
}

.svgSuccess, .svgsuccess {
  fill: var(--bs-green);
}

.svgDanger, .svgdanger {
  fill: var(--bs-red);
}

.svgWarning, .svgwarning {
  fill: var(--bs-yellow);
}

.svgPrimary, .svgprimary {
  fill: var(--bs-cyan);
}

.svgGrey, .svggrey {
  fill: var(--bs-gray);
}

.svg05 {
  height: 0.5em;
  width: 0.5em;
}

.svg07 {
  height: 0.7em;
  width: 0.7em;
}

.svg09 {
  height: 0.9em;
  width: 0.9em;
}

.svg12 {
  height: 1.2em;
  width: 1.2em;
}

.svg14 {
  height: 1.4em;
  width: 1.4em;
}

.svg16 {
  height: 1.6em;
  width: 1.6em;
}

.svg18 {
  height: 1.8em;
  width: 1.8em;
}

.svg20 {
  height: 2em;
  width: 2em;
}

.h10 > svg {
  height: 10px;
  width: 10px;
}

.h15 > svg {
  height: 15px;
  width: 15px;
}

.h20 > svg {
  height: 20px;
  width: 20px;
}

.hovSvg > svg {
  width: 15px !important;
  height: 15px !important;
}

.hovSvg > svg:hover {
  color: var(--bs-green);
}

.hovSvg {
  transition: all 0.4s;
}

.hovSvg:hover {
  width: 3em !important;
  height: 3em !important;
}

.hovSvgSoft {
  transition: all 0.4s;
}

.hovSvgSoft:hover {
  width: 1.5em !important;
  height: 1.5em !important;
}

/* kept for compatibility */
/* 
.cGreen > svg {
  fill: var(--w-green);
  height: 20px;
  width: 20px;
}

.cBlue > svg {
  fill: var(--w-light-blue);
  height: 20px;
  width: 20px;
}

.cRed > svg {
  fill: var(--w-red);
  height: 20px;
  width: 20px;
}

.cGrey > svg {
  fill: var(--w-grey);
  height: 20px;
  width: 20px;
}

.cRed > svg:hover,
.cGreen > svg:hover,
.cGrey > svg:hover {
  fill: var(--w-link-color);
}

.svgSuccess {
  fill: var(--w-green);
}

.svgDanger {
  fill: var(--w-red);
}

.svgWarning {
  fill: var(--w-yellow);
}

.svgPrimary {
  fill: var(--w-light-blue);
}

.svgGrey {
  fill: var(--w-grey);
}

.svg05 {
  height: 0.5em;
  width: 0.5em;
}

.svg07 {
  height: 0.7em;
  width: 0.7em;
}

.svg09 {
  height: 0.9em;
  width: 0.9em;
}

.svg12 {
  height: 1.2em;
  width: 1.2em;
}

.svg14 {
  height: 1.4em;
  width: 1.4em;
}

.svg16 {
  height: 1.6em;
  width: 1.6em;
}

.svg18 {
  height: 1.8em;
  width: 1.8em;
}

.svg20 {
  height: 2em;
  width: 2em;
}

.h10 > svg {
  height: 10px;
  width: 10px;
}

.h15 > svg {
  height: 15px;
  width: 15px;
}

.h20 > svg {
  height: 20px;
  width: 20px;
}

.hovSvg > svg {
  width: 15px !important;
  height: 15px !important;
}

.hovSvg > svg:hover {
  color: var(--w-green);
}

.hovSvg {
  transition: all 0.4s;
}

.hovSvg:hover {
  width: 3em !important;
  height: 3em !important;
}

.hovSvgSoft {
  transition: all 0.4s;
}

.hovSvgSoft:hover {
  width: 1.5em !important;
  height: 1.5em !important;
} */
